import moment from 'moment';
import { FaClock } from "react-icons/fa";

export function isValidDate(d) {
	return d instanceof Date && !isNaN(d);
}

export function formatMinutesToTime(minutes) {
	let hours = parseInt(minutes / 60);
	let mins = parseInt(minutes % 60);
	let sign = "";

	if (hours >= 0 && mins < 0)
		sign = "-";

	if (mins < 0)
		mins = mins * (-1);

	if (mins < 10)
		mins = '0' + mins;

	return `${sign}${hours}h ${mins}'`;
}

export function formatSecondsToTime(seconds, format) {
	let minutes = parseInt(seconds / 60);
	let hours = parseInt(minutes / 60);

	let mins = minutes % 60;
	if (mins > 0 && mins < 10)
		mins = '0' + mins;

	let secs = seconds % 60;
	if (secs < 10)
		secs = '0' + secs;

	if (format === "hm") {
		return `${hours}h ${mins}m`;
	}
	else if (format === "hms") {
		return `${hours}h ${mins}m ${secs}s`;
	}
	else
		return `${hours}:${mins}:${secs}`;
}

export function getDiffTime(start, end, type) {
	let totalTime = 0;
	if (type === undefined)
		type = "minutes";

	if (start != null && end != null) {

		let endTime = moment.utc(end).local();
		let startTime = moment.utc(start).local();

		totalTime = endTime.diff(startTime, type);

		if (isNaN(totalTime))
			totalTime = 0;
	}

	return totalTime;
}

export function getDiffDays(start, end) {
	let totalDays = 0;

	if (end == null) {
		totalDays = 1;
	}
	else {
		const endTime = moment.utc(end).local();
		const startTime = moment.utc(start).local();
		totalDays = endTime.diff(startTime, "days") + 1;
	}

	return totalDays;
}

export function getWorkedTime(times, date) {
	var totalSeconds = getWorkedTimeRaw({ times, date, unit: "seconds" });
	const formatedTime = formatSecondsToTime(totalSeconds, "hm");

	return formatedTime;
}

export function getWorkedTimeRaw(params) {
	var totalTime = 0;
	const { times, date, unit, employee_id } = params;

	times.filter(time => {
		if (employee_id && time.employee.id !== employee_id)
			return false;
		return date ? moment.utc(time.start).local().format("DD/MM/YYYY") === moment.utc(date).local().format("DD/MM/YYYY") : time
	}).forEach((time) => {
		// just calc if it's work_time
		if (time.type !== undefined && time.type.work_time) {
			var getDiff = getDiffTime(time.start, time.end, unit);
			// console.log("getDiff", getDiff);
			// console.log("start", time.start);
			// console.log("end", time.end);
			totalTime += getDiff;
		}
	});

	return totalTime;
}

export function getBalanceHours(objective_hours, total_today) {

	const objective_seconds = objective_hours * 3600;
	const hours_today = total_today / 3600;
	let totalSeconds = total_today - objective_seconds;
	const formatedTime = formatSecondsToTime(totalSeconds, "hm");
	let short = 0;
	let short_raw = 0;

	if (total_today > objective_seconds) {
		short_raw = total_today / 3600 - objective_hours;
		if (short_raw < 1 && short_raw >= 0.01667) { // Si es menos de una hora pero más de un minuto
			const minutes = short_raw * 60;
			short = Math.floor(minutes).toString() + "m"; // No "+" para minutos
		} else if (short_raw >= 1) { // Más de una hora
			const formattedValue = new Intl.NumberFormat("de-DE", { maximumSignificantDigits: 2 }).format(short_raw);
			short = "+" + formattedValue.toString() + "h";
		} else if (short_raw < 0.01667) {
			// Mostramos "0" si es menos de un minuto
			short = "0";
		}
	} else if (total_today < objective_seconds) {
		short_raw = hours_today - objective_hours;
		if (short_raw > -1 && short_raw <= -0.01667) { // Si es menos de una hora pero más de un minuto negativo
			const minutes = short_raw * 60;
			short = Math.ceil(minutes).toString() + "m"; // Negativo
		} else if (short_raw <= -1) { // Más de una hora negativa
			const formattedValue = new Intl.NumberFormat("de-DE", { maximumSignificantDigits: 2 }).format(short_raw);
			short = formattedValue + "h";
		} else if (short_raw > -0.01667) {
			// Mostramos "0" si es menos de un minuto negativo
			short = "0";
		}
	}

	const balance_hours = {
		short,
		short_raw,
		formated: formatedTime
	};

	return balance_hours;
}



// gets a time list and extracts total seconds
export function getWorkedTimeCounter(times) {
	var totalTime = 0;

	times.forEach((time) => {
		if (time.type !== undefined && time.type.work_time) {
			totalTime += getDiffTime(time.start, time.end, "seconds");
		}
	});

	const formatedTime = formatSecondsToTime(totalTime);
	return formatedTime;
}

// gets a time list and extracts total seconds
export function getTimeCounterByType(times, type) {
	var totalTime = 0;

	times.forEach((time) => {
		if (time.type !== undefined && time.type.id === type.id) {
			totalTime += getDiffTime(time.start, time.end, "minutes");
		}
	});

	return totalTime;
}

// gets first start and last end from a list of time intervals
export function getFirstLastInterval(timeIntervals) {
	if (!Array.isArray(timeIntervals) || timeIntervals.length === 0) {
		return '';
	}

	let firstStart = timeIntervals[0].start;
	let lastEnd = timeIntervals[0].end;

	for (let i = 1; i < timeIntervals.length; i++) {
		if (timeIntervals[i].start < firstStart) {
			firstStart = timeIntervals[i].start;
		}
		if (timeIntervals[i].end > lastEnd) {
			lastEnd = timeIntervals[i].end;
		}
	}

	return `🕒 ${firstStart} - ${lastEnd}`;
}